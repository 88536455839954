import React from 'react';
import { connect } from 'react-redux';
import { load_data, fetchStudentList, fetchQuestionDetail, fetchQuestionFeedback, setValue } from './actions';
import { Table, Button, Radio, Modal, Input, Tooltip, Spin, message, Select, Typography } from "antd";
import RecordComponent from '../../components/RecordAudio'
import { checkPermission, getSearchQuery, uploadOSS } from '../../utils/utils';
import Editor from "../../components/Editor/index";
import { get } from "../../common/request";

import './index.scss';

const { TextArea } = Input;
const RadioGroup = Radio.Group;
const { Paragraph } = Typography;

const styles = {
    mb: {
        marginBottom: 15
    },
    flexc: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '20px'
    },
    audio: {
        height: 40,
        marginLeft: 15
    }
};

class InterviewStudentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoUrl: '',
            videoModal: false,
            loadingModal: false,
            showUserModal: false,
            userInfo: {},
            templateList: [], // 模版列表
        };
    }

    componentDidMount() {
        this.getStudentList();        // 获取服务端数据
        this.getQuestion()
        this.getTemplateList()
    };

    getTemplateList = () => {
      get("/examine/template/list").then(res => {
        this.setState({ templateList: res?.obj || [] })
      })
    }

    /** 获取学员数据 */
    getStudentList = async () => {
        let id = getSearchQuery('questionId');
        let params = {
            questionId: id,
            page: this.props.page,
            pageSize: this.props.pageSize,
        }
        await this.props.dispatch(fetchStudentList(params));
    };

    /** 获取题目数据 */
    getQuestion = () => {
        let id = getSearchQuery('questionId');
        this.props.dispatch(fetchQuestionDetail(id));
    };

    handleSelect = async page => {
        await this.props.dispatch(setValue('filterStatus', 'ALL'));
        await this.props.dispatch(setValue('page', page));
        this.getStudentList();
    };

    /** 改变每页显示大小 */
    onShowSizeChange = async (current, pageSize) => {
        await this.props.dispatch(setValue('filterStatus', 'ALL'));
        await this.props.dispatch(setValue('page', 1));
        await this.props.dispatch(setValue('pageSize', pageSize));
        this.getStudentList();
    }

    /** 点击编辑弹框 */
    mModify = userInfo => {
        this.setState({
            showUserModal: true,
            userInfo,
        });
    };

    /** 关闭弹窗 */
    handleCancel = () => {
        this.setState({
            showUserModal: false,
            userInfo: {},
        });
    };

    verifyData = () => {
        if (!this.state.userInfo || !this.state.userInfo.comment || this.state.userInfo.comment == '') {
            message.warn('文字点评不能为空');
            return true;
        }
        return false;
    };

    videoHandleCancel = () => {
        this.setState({ videoModal: false, videoUrl: '' });
    }

    modifyUser = async () => {
        let running = this.refs['recordBlob'].state.running;
        if (running) return message.warn('请完成录音');
        if (this.verifyData()) return;
        const { cLength, cSize, commentUrl, comment, questionId, id, remark, userId } = this.state.userInfo
        // let classId = getSearchQuery('classId');
        // let exerciseId = getSearchQuery('exerciseId');
        let fileName = `interview/teacher/audio/${questionId}/${userId}/${Date.now()}.m4a`;
        let fileAudio = this.refs['recordBlob'].state.blob;
        let params = {
            // classId,
            comment,
            questionId,
            id,
            remark,
            userId,
            // exerciseId
        }
        cLength ? params.cLength = cLength : '';
        cSize ? params.cSize = cSize : '';
        commentUrl ? params.commentUrl = commentUrl.split('aliyuncs.com/')[1] : '';
        this.setState({ loadingModal: true });
        if (fileAudio !== null) { //如果有新语音上传，则覆盖老数据，使用新语音
            let cLength = this.refs['recordBlob'].state.duration;
            let cSize = this.refs['recordBlob'].state.fileSize;
            params.cLength = cLength;
            params.cSize = cSize;
            params.commentUrl = fileName;
            await uploadOSS(fileName, fileAudio, 'jd-user');
        }
        let cb = this.getStudentList;
        await this.props.dispatch(fetchQuestionFeedback(params, cb));
        this.setState({ loadingModal: false, showUserModal: false })
    };

    /** 筛选状态发生改变 */
    changeFilterStatus = async event => {
        let value = event.target.value;
        await this.props.dispatch(setValue('filterStatus', value));
        await this.getStudentList();
        if (value == 1 || value == 0) {
            let data = this.getCommentArr(this.props.responseObj.content, value);
            let newData = this.props.responseObj;
            newData.content = data;
            this.props.dispatch(load_data(newData));
        }
    };

    getCommentArr = (arr, val) => { // type=1,
        let list = [];
        val == 1 ? arr.map(item => (
            item.comment && list.push(item)
        )) :
            arr.map(item => (
                !item.comment && list.push(item)
            ))
        return list;
    }

    getTooltip = (str) => {
        return <div dangerouslySetInnerHTML={{ __html: str ? str : '' }}></div>
    };

    /** antd table 的数据格式约定配置 */
    generateColumns() {
        const columns = [
            {
                title: '用户手机',
                dataIndex: 'mobile',
                width: '12%',
            },
            {
                title: '学员做答',
                width: '18%',
                render: (text, record) => {
                    return (
                        <Tooltip title={this.getTooltip(record.remark)}>
                            <div className="ellipsis1" style={{height: '22px'}} dangerouslySetInnerHTML={{__html: record.remark}}/>
                        </Tooltip>
                    )
                }
            },
            {
                title: '学员上传',
                width: '20%',
                render: (text, record) => {
                    return (
                        <div>
                            {record.voiceUrl && <audio style={{ 'height': '35px', width: '250px' }} controls="controls" src={record.voiceUrl}>您的浏览器不支持 audio 标签</audio>}
                            {record.videoUrl && <Button type="link" onClick={() => this.setState({ videoUrl: record.videoUrl, videoModal: true })}>视频-点我播放</Button>}
                        </div>
                    )
                }
            },
            {
                title: '老师评论',
                width: '20%',
                render: (text, record) => {
                    return (
                        <Tooltip title={this.getTooltip(record.comment)}>
                            <div className="ellipsis1" style={{height: '22px'}} dangerouslySetInnerHTML={{__html: record.comment}}/>
                        </Tooltip>
                    )
                }
            },
            {
                title: '老师语音',
                width: '20%',
                render: (text, record) => {
                    return (
                        <div>
                            {record.commentUrl && <audio style={{ 'height': '35px', width: '250px' }} controls="controls" src={record.commentUrl}>您的浏览器不支持 audio 标签</audio>}
                        </div>
                    )
                }
            },
            {
                title: '操作',
                width: '10%',
                render: (text, record) => {
                    return (
                        <div>
                            {checkPermission('interview:stu:feedback') && <Button type="link" onClick={() => this.mModify(record)}>编辑</Button>}
                        </div>
                    )
                }
            },
        ];
        return columns;
    }

    selectHandler = (id, option) => {
      this.setState({
        userInfo: {
          ...(this.state.userInfo || {}),
          comment: (this.state.userInfo?.comment || "") + option.content,
        },
      })
      message.success("已添加到点评末尾")
    }

    renderModalTitle = () => {
      const returnOptions = () => {
        const arr = this.state.templateList
        if (!Array.isArray(arr)) {
          return []
        }

        const copyHandler = (e, obj) => {
          e.preventDefault()
        }

        const applyHandler = (e, obj) => {
          e.preventDefault()
          this.setState({
            userInfo: {
              ...(this.state.userInfo || {}),
              comment: (this.state.userInfo?.comment || "") + obj.content,
            },
          })
          message.success("添加成功")
        }

        return arr.map(obj => {
          return {
            ...obj,
            label: (
              <div className="sdsjdshdj2">
                {obj.templateName}
                {/* <span className="sdhjs">{obj.templateName}</span> */}
                {/* <div>
                  <a onClick={e => applyHandler(e, obj)} href="#">添加到点评末尾</a>
                  <Paragraph copyable={{ text: obj?.content }}>
                    <a style={{ marginLeft: 6 }} onClick={e => copyHandler(e, obj)} href="#">复制</a>
                  </Paragraph>
                </div> */}
              </div>
            ),
            value: obj.id,
          }
        })
      }

      return (
        <div className="row23029032h">
          <div>学员面试点评</div>
          <div style={{ marginRight: 30 }}>
            <Select
              value={null}
              className="selcectioncsod"
              options={returnOptions()}
              placeholder="选择点评模板"
              onChange={this.selectHandler}
            />
          </div>
        </div>
      )
    }

    render() {
        const { responseObj, question, isFetching } = this.props;
        const { material } = question;
        return (
            <div className="interviewPage">
                <div className="interview-container">
                    {/* <div className="flexrc childDiv borderBottom">
                        <span className="mb5 questionTest">练习：</span>
                        <span className="marginRight25 mb5">{getSearchQuery('title')}</span>
                    </div> */}

                    <div className="flexrc childDiv testModule">
                        <span className="questionTest">试题：</span>
                        {/* <span className="marginRight25">{getSearchQuery('question')}</span> */}
                        <span dangerouslySetInnerHTML={{ __html: question.question }} style={{ 'fontSize': '14px' }}></span>
                    </div>

                    {
                        material && <div className="flexrc childDiv testModule">
                                    <span className="questionTest">材料：</span>
                                    <span dangerouslySetInnerHTML={{ __html: material }} style={{ 'fontSize': '14px' }}></span>
                                </div>
                    }

                </div>
                {
                    !this.props.isFetching &&
                    <div>
                        <div style={{ height: '45px' }}>
                            <RadioGroup onChange={this.changeFilterStatus} value={this.props.filterStatus}>
                                <Radio value="ALL">全部</Radio>
                                <Radio value={1}>已点评</Radio>
                                <Radio value={0}>未点评</Radio>
                            </RadioGroup>
                        </div>

                        <Table
                            columns={this.generateColumns()}
                            dataSource={responseObj.content}
                            rowKey={record => record.id}
                            loading={isFetching}
                            pagination={{
                                total: responseObj.totalElements,
                                pageSize: this.props.pageSize,
                                current: this.props.page,
                                onChange: this.handleSelect,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                showTotal: (total) => `共有${total}条数据`
                            }}
                        />
                        <Modal title="学生视频" footer={null} visible={this.state.videoModal} onCancel={this.videoHandleCancel}>
                            <video width="480" height="380" src={this.state.videoUrl} controls autoPlay="autoplay">您的浏览器不支持 HTML5 video 标签</video>
                        </Modal>

                        <Modal
                            title={this.renderModalTitle()}
                            visible={this.state.showUserModal}
                            onOk={this.modifyUser}
                            onCancel={this.handleCancel}
                            width="800"
                            okText='提交'
                            destroyOnClose={true}
                            confirmLoading={this.state.loadingModal}
                        >
                            <Spin tip="点评上传中，请稍等..." spinning={this.state.loadingModal}>
                                    <div style={{ marginBottom: 8 }}>学员做答</div>
                                    {
                                        this.state.userInfo.remark &&
                                        <div className="studentRemark">{this.state.userInfo.remark || ''}</div>
                                    }
                                    {this.state.userInfo.voiceUrl && <div style={styles.flexc}>
                                        <label>学员语音：</label>
                                        <audio style={styles.audio} controls="controls" src={this.state.userInfo.voiceUrl}>您的浏览器不支持 audio 标签</audio>
                                    </div>}

                                    <div style={{marginTop: '10px', marginBottom: 8}}>文字点评：</div>
                                    {/* <TextArea
                                        maxLength="500"
                                        autosize={{ minRows: 3 }}
                                        value={this.state.userInfo.comment || ''}
                                        onChange={e => this.setState({ userInfo: { ...this.state.userInfo, comment: e.target.value } })}
                                        style={{ 'fontSize': '14px' }}
                                    /> */}
                                    <Editor
                                        content={this.state.userInfo.comment}
                                        onChange={value => this.setState({ userInfo: { ...this.state.userInfo, comment: value } })}
                                    />
                                    {this.state.userInfo.commentUrl && <div style={styles.flexc}>
                                        <div>近一次老师语音点评：</div>
                                        <audio style={styles.audio} controls="controls" src={this.state.userInfo.commentUrl}>您的浏览器不支持 audio 标签</audio>
                                    </div>}
                                    <div style={{marginTop: '10px'}}>语音点评(可选)：</div>
                                    <RecordComponent ref="recordBlob"></RecordComponent>
                            </Spin>
                        </Modal>
                    </div>
                }
            </div>
        )
    }
}

function mapStatetoProps(state) {

    return {
        ...state.interviewStudentList
    };
}

export default connect(mapStatetoProps)(InterviewStudentList);