import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Select, Cascader, Table, Input, Tooltip } from "antd";
import {
  fetchQuestions,
  setKeyValue,
  resetSearchStatus,
  fetchInterviewCondition,
  changeCommon,
} from "./actions";
import { checkPermission } from "../../utils/utils";
import "./index.scss";
import { examineType_real, examineType_flexable } from "../../common/consts";

const Option = Select.Option;

class InterviewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isUsedInFlexablescene = false;

  componentWillMount() {
    const curPath = this.props.match?.path;
    if (curPath.includes("/flexibleInterview")) {
      this.isUsedInFlexablescene = true;
    }
    this.fetchInterviewHandler();
    this.props.dispatch(fetchInterviewCondition());
  }

  getTooltip = (str) => {
    return <div dangerouslySetInnerHTML={{ __html: str ? str : "" }}></div>;
  };

  /** 加载面试试题的handler */
  fetchInterviewHandler = () => {
    let obj = {};
    obj.page = this.props.page || 1;
    obj.pageSize = this.props.pageSize || 10;
    let keys = [
      "type",
      "school",
      "categoryId",
      "query",
      "questionTag",
      "title",
      "year",
    ];
    keys.map((key) => {
      this.props[key] && (obj[key] = this.props[key]);
    });
    obj = this.checkCategoryId(obj);
    if (this.isUsedInFlexablescene) {
      obj.examineType = examineType_flexable;
    } else {
      obj.examineType = examineType_real;
    }
    this.props.dispatch(fetchQuestions(obj));
  };

  checkCategoryId = (obj) => {
    let categoryId = this.props.categoryId;
    if (Object.prototype.toString.call(categoryId) !== "[object Array]")
      return obj;
    for (let id of categoryId) {
      if (id) obj.categoryId = id;
    }
    return obj;
  };

  handleSelect = async (eventKey) => {
    await this.props.dispatch(setKeyValue("page", eventKey));
    this.fetchInterviewHandler();
  };

  selectRecruitType = async (value) => {
    await this.props.dispatch(setKeyValue("type", value));
    await this.props.dispatch(setKeyValue("school", ""));
    let _schools = [];
    this.props.recruitTypeSchool.map((item) => {
      if (item.id === Number(value)) {
        for (let index = 0; index < item.subset.length; index++) {
          _schools[index] = {
            value: item.subset[index].id,
            label: item.subset[index].name,
          };
        }
      }
      this.props.dispatch(
        changeCommon({
          key: "schools",
          val: [{ value: "", label: "全部" }, ..._schools],
        })
      );
    });
    await this.props.dispatch(setKeyValue("page", 1));
    this.fetchInterviewHandler();
  };

  selectSchool = async (value) => {
    await this.props.dispatch(setKeyValue("school", value));
    await this.props.dispatch(setKeyValue("page", 1));
    this.fetchInterviewHandler();
  };

  selectCategory = async (value) => {
    await this.props.dispatch(setKeyValue("categoryId", value));
    await this.props.dispatch(setKeyValue("page", 1));
    this.fetchInterviewHandler();
  };

  selectTag = async (value) => {
    await this.props.dispatch(setKeyValue("questionTag", value));
    await this.props.dispatch(setKeyValue("page", 1));
    this.fetchInterviewHandler();
  };

  selectYear = async (value) => {
    await this.props.dispatch(setKeyValue("year", value));
    await this.props.dispatch(setKeyValue("page", 1));
    this.fetchInterviewHandler();
  };

  handleInput = (value) => {
    this.props.dispatch(setKeyValue("title", value));
  };

  handleQuery = (value) => {
    this.props.dispatch(setKeyValue("query", value));
  };

  handleSearch = () => {
    this.props.dispatch(setKeyValue("page", 1));
    this.fetchInterviewHandler();
  };

  resetSearch = async () => {
    await this.props.dispatch(resetSearchStatus());
    this.fetchInterviewHandler();
  };

  /** 改变分页大小的时候触发 */
  onShowSizeChange = async (current, size) => {
    await this.props.dispatch(setKeyValue("page", 1));
    await this.props.dispatch(setKeyValue("pageSize", size));
    this.fetchInterviewHandler();
  };

  sourceHandler = (arr) => {
    if (!arr.length) return [];
    if (arr[0].name == "全部") return arr;
    let defaultItem = { id: "", name: "全部", subset: [] };
    arr.map((item) => {
      if (item.subset && item.subset.length) {
        this.sourceHandler(item.subset);
      }
    });
    arr.unshift(defaultItem);
    return arr;
  };

  changeCategoryId = async (value) => {
    await this.props.dispatch(setKeyValue("categoryId", value));
    this.fetchInterviewHandler();
  };

  returnInfoUrl = (obj) => {
    if (this.isUsedInFlexablescene) {
      return `/interviewInfo/${obj.id}?isFlexableScene=1`;
    } else {
      return `/interviewInfo/${obj.id}`;
    }
  };

  generateTableColumns = () => {
    let columns = [
      {
        title: "标题",
        with: "40%",
        render: (text, record) => {
          return (
            <div>
              {(checkPermission("sys:view:gkinfo") && (
                <Link to={this.returnInfoUrl(record)}>{record.title}</Link>
              )) || <span>{record.title}</span>}
              <div>
                {record.questions.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        marginTop: "8px",
                        paddingBottom:
                          index !== record.questions.length - 1 ? "5px" : "0",
                        borderBottom:
                          index !== record.questions.length - 1
                            ? "1px dashed #eee"
                            : "none",
                      }}
                    >
                      {/* <div style={{color: '#777', flexBasis: '62%', maxWidth: '680px', paddingRight: '20px',  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{item.question}</div> */}
                      <Tooltip title={this.getTooltip(item.question)}>
                        <div
                          style={{
                            color: "#777",
                            flexBasis: "62%",
                            maxWidth: "600px",
                            paddingRight: "20px",
                            height: "24px",
                          }}
                          className="ellipsis1"
                          dangerouslySetInnerHTML={{ __html: item.question }}
                        />
                      </Tooltip>
                      <div style={{ flexBasis: "150px" }}>
                        未点评数：{item.notCommentCount}
                      </div>
                      <div style={{ flexBasis: "150px" }}>
                        提交总数：{item.payCommentCount}
                      </div>
                      <div>
                        {checkPermission("sys:view:stu") && (
                          <Link
                            to={`/interviewStudentList?exerciseId=${record.id}&questionId=${item.id}`}
                          >
                            查看用户答题记录
                          </Link>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        },
      },
      /* { title: '地区', with: '30%', dataIndex: 'area' },
            { title: '年份', with: '30%', dataIndex: 'year' }, */
      {
        title: "操作",
        width: "60px",
        render: (text, record) => {
          return (
            <div style={{ position: "absolute", top: "10px" }}>
              {checkPermission("sys:view:gkinfo") && (
                <Button type="link">
                  <Link to={this.returnInfoUrl(record)}>编辑</Link>
                </Button>
              )}
            </div>
          );
        },
      },
    ];
    return columns;
  };

  returnNewUrl = () => {
    if (this.isUsedInFlexablescene) {
      return "/interviewRelease?isFlexableScene=1";
    } else {
      return "/interviewRelease";
    }
  };

  /** 生成antd table的表头数据 */
  renderHeader = () => (
    <div className="commonTableTitle">
      试题列表
      {checkPermission("sys:view:edit") && (
        <Button type="primary">
          <Link to={this.returnNewUrl()}>添加试题</Link>
        </Button>
      )}
    </div>
  );

  render() {
    const onSearchSchool = (value) => {
      // console.log('search:', value);
    };

    const filterOptionSchool = (input, option) => {
      // console.log('option', option);
      // console.log('input', input);
      // console.log((option?.label ?? '').toLowerCase().includes(input.toLowerCase()));
      return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    };

    let columns = this.generateTableColumns();
    let resource = this.sourceHandler(this.props.resource);
    return (
      <div className="interviewListContainer">
        {checkPermission("sys:view:page") && (
          <div className="commonSearchBox">
            {!this.isUsedInFlexablescene ? (
              <div>
                <label>多元升学类型</label>
                <Select
                  value={this.props.type}
                  className="commonW150"
                  dropdownMatchSelectWidth={false}
                  onChange={(value) => {
                    this.selectRecruitType(value);
                  }}
                >
                  <Option value="">全部</Option>
                  {this.props.recruitTypeSchool.map((item, idx) => {
                    return (
                      <Option key={idx} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            ) : null}

            {!this.isUsedInFlexablescene ? (
              <div>
                <label>　　　　院校</label>
                {/* <Select
                        disabled={this.props.schools.length <= 0}
                        value={this.props.school}
                        className="commonW150"
                        dropdownMatchSelectWidth={false}
                        onChange={value => { this.selectSchool(value) }}>
                        <Option value="">全部</Option>
                        {this.props.schools.map((item, idx) => {
                            return <Option key={idx} value={item.id}>{item.name}</Option>
                        })}
                    </Select> */}
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(value) => {
                    this.selectSchool(value);
                  }}
                  onSearch={onSearchSchool}
                  filterOption={filterOptionSchool}
                  options={this.props.schools}
                  disabled={this.props.schools.length <= 0}
                  value={this.props.school}
                  className="commonW150"
                  dropdownMatchSelectWidth={false}
                />
              </div>
            ) : null}

            {!this.isUsedInFlexablescene ? (
              <div>
                <label>　　面试题型</label>
                <Select
                  value={this.props.categoryId}
                  className="commonW150"
                  dropdownMatchSelectWidth={false}
                  onChange={(value) => {
                    this.selectCategory(value);
                  }}
                >
                  <Option value="">全部</Option>
                  {this.props.categories.map((item, idx) => {
                    return (
                      <Option key={idx} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            ) : null}

            <div>
              <label>　　　　年份</label>
              <Select
                value={this.props.year + ""}
                className="commonW150"
                dropdownMatchSelectWidth={false}
                onChange={(value) => {
                  this.selectYear(value);
                }}
              >
                <Option value="">全部</Option>
                {this.props.years &&
                  this.props.years.map((year, idx) => {
                    return (
                      <Option key={idx} value={year + ""}>
                        {year}年
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <div>
              <label>　　试卷名称</label>
              <Input
                style={{ width: "429px" }}
                placeholder="输入试卷名称查询"
                value={this.props.title}
                onChange={(e) => this.handleInput(e.target.value)}
                onPressEnter={() => this.handleSearch()}
              />
            </div>

            <div>
              <label>　　　　问题</label>
              <Input
                style={{ width: "429px" }}
                placeholder="输入问题查询"
                value={this.props.query}
                onChange={(e) => this.handleQuery(e.target.value)}
                onPressEnter={() => this.handleSearch()}
              />
            </div>

            <div>
              <label>　　是否真题</label>
              <Select
                value={this.props.questionTag}
                className="commonW150"
                dropdownMatchSelectWidth={false}
                onChange={(value) => {
                  this.selectTag(value);
                }}
              >
                <Option value="">全部</Option>
                {/* <Option value={1}>是</Option>
                        <Option value={0}>否</Option> */}
                {this.props.tags &&
                  this.props.tags.map((item, idx) => {
                    return (
                      <Option key={idx} value={item.value}>
                        {item.desc}
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <div style={{ marginLeft: "55px" }}>
              <Button
                disabled={this.props.isFetching}
                type="primary"
                onClick={this.handleSearch}
              >
                搜索
              </Button>
            </div>
            <div>
              <Button
                disabled={this.props.isFetching}
                type="danger"
                onClick={this.resetSearch}
              >
                重置
              </Button>
            </div>
          </div>
        )}
        <Table
          columns={columns}
          dataSource={this.props.items}
          rowKey={(record) => record.id}
          title={this.renderHeader}
          loading={this.props.isFetching}
          pagination={{
            showSizeChanger: true,
            pageSize: this.props.pageSize,
            pageSizeOptions: ["10", "20", "50", "100"],
            onShowSizeChange: this.onShowSizeChange,
            current: this.props.page,
            total: this.props.totalElements,
            showTotal: (total) => `共有${total}条数据`,
            onChange: this.handleSelect,
            showQuickJumper: true,
          }}
        />
      </div>
    );
  }
}

function mapStatetoProps(state) {
  const { years } = state.questions;

  const { resource } = state.interviewCategory;

  const { tags } = state.essayCategory;

  return {
    years,
    resource,
    tags,
    ...state.interviewList,
  };
}

export default connect(mapStatetoProps)(InterviewList);
