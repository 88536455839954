import { checkPermission } from "../../utils/utils"
import PropTypes from "prop-types"
import { Button } from "antd"

const P = ({
  code,
  children,
  ...otherProps
}) => {
  if (!checkPermission(code)) {
    return null
  }

  return (
    <Button {...otherProps}>{children}</Button>
  )
}

P.propTypes = {
  code: PropTypes.string, // 权限名
}

export default P