import React, { useState, useEffect } from "react"
import { Card, Form, Col, Row, Button, message } from "antd"
import FormList from "../../../components/formItem/index"
import { post, get } from "../../../common/request"

const CommentsTemplateForm = (props) => {
  const id = props.match?.params?.id
  const [formInstance] = Form.useForm()

  const [loadingDetail, setLoadingDetail] = useState(false)

  const formListConfig = [
    {
      label: "模版名称",
      key: "templateName",
      type: "input",
      required: true,
    },
    {
      label: "模板内容",
      key: "content",
      type: "richtext",
      required: true,
      height: 500,
    }
  ]

  const saveHandler = async () => {
    try {
      const values = await formInstance.validateFields()
      const postData = { ...values }
      if (id) {
        postData.id = id
      }
      return post("/examine/template/saveOrUpdate", postData).then(res => {
        message.success("操作成功")
        props.history.goBack()
      })
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!id) {
      return
    }
    setLoadingDetail(true)
    get(`/examine/template/${id}`).then(res => {
      formInstance.setFieldsValue(res.obj || {})
    }).finally(() => {
      setLoadingDetail(false)
    })
  }, [id])

  return (
    <Card title={id ? "编辑模板" : "新增点评模板"} loading={loadingDetail}>
      <Form form={formInstance} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
      <FormList
        span={24}
        list={formListConfig}
      />
      </Form>
      <Row>
        <Col offset={2}>
          <Button onClick={saveHandler} type="primary">保存</Button>
        </Col>
      </Row>
    </Card>
  )
}

export default CommentsTemplateForm