export const OSS_PUB_GK_COURSE = "gk-course"; // 视频的私有仓库
export const OSS_PUB_GK_NEWS = "gk-news"; // 视频的公共仓库
export const PUB_TO_ENDPOINT = {
  [OSS_PUB_GK_COURSE]: "oss-cn-beijing.aliyuncs.com",
  [OSS_PUB_GK_NEWS]: "oss-cn-beijing.aliyuncs.com",
}; // OSS库的endPoint

export const WORK_FILTER_STATUS_ALL = "all";
export const WORK_FILTER_STATUS_DONE = "done";
export const WORK_FILTER_STATUS_NOT = "not";

export const examineType_real = 0;
export const examineType_flexable = 1;
